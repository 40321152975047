import { Constant } from './index'

export const ORDER_STATUS = Object.freeze(
  new Constant({
    WaitToPay: { value: 0, name: '待支付' },
    Complete: { value: 1, name: '已支付' },
    Refund: { value: 2, name: '已退款' },
    Unfinished: { value: 3, name: '未完成' },
    Revoked: { value: 4, name: '已撤销(刷卡支付)' },
    Paying: { value: 5, name: '用户支付中' },
    Fail: { value: 6, name: '已取消' },
    Expired: { value: 7, name: '已失效' },
    ShipmentFailed: { value: 8, name: '发货失败' },
  }),
)

export const PAY_STATUS_SUCCESS = Object.freeze([
  ORDER_STATUS.enums.Complete.value,
])
export const PAY_STATUS_FAIL = Object.freeze([
  ORDER_STATUS.enums.Refund.value,
  ORDER_STATUS.enums.Unfinished.value,
  ORDER_STATUS.enums.Revoked.value,
  ORDER_STATUS.enums.Fail.value,
  ORDER_STATUS.enums.Expired.value,
  ORDER_STATUS.enums.ShipmentFailed.value,
])

export const PRODUCT_TYPE = Object.freeze(
  new Constant({
    CourseYxk: { value: 20, desc: '机构代卖云享课', label: '机构代卖云享课' },
    CourseOrg: { value: 21, desc: '机构自有课程', label: '机构自有课程' },
    CourseOrgOffline: { value: 22, desc: '机构线下课程', label: '机构线下课程' },
    CourseOrgForYxk: { value: 200, desc: '机构代卖云享课课程包', label: '机构代卖云享课课程包' },
    CourseOrgBag: { value: 210, desc: '机构课程包', label: '机构课程包' },
    Mock: { value: 32, desc: '学费代缴', label: '学费代缴' },
  }),
)

export const PAY_TYPE = Object.freeze({
  Ali: 0,
  Wechat: 3,
  AliPC: 5,
  AliH5: 6,
  WechatJsApi: 31,
  WechatNative: 32,
  WechatH5: 33,
})

export const OFFER_TYPE = Object.freeze({
  FullPurchase: 2,
  Discount: 3,
  CheapDrainage: 80,
})
